<template>
  <section>
    <div class="sectionInner" v-if="activeTab == 'haisaiCitizenSectionServiceOne'">
      <div class="updateDateTimeArea" :class="tableColor">
        <span>更新日時</span>
        <span v-if="result">{{ result.updateDateTime }}</span>
        <img
          src="@/assets/reload.png"
          :class="isLoading ? 'rotation' : ''"
          @click="reloadWaitingStatus"
        />
      </div>
      <table class="basicTable" v-if="result" :class="tableColor">
        <tr>
          <th>手続き名</th>
          <th>待ち数</th>
          <th>待ち時間</th>
        </tr>
        <tr>
          <th>転入</th>
          <td>{{ findWatingNumberOrTime("movingIn", "number") }}</td>
          <td rowspan="4">{{ findWatingNumberOrTime("movingIn", "time") }}</td>
        </tr>
        <tr>
          <th>転居</th>
          <td>{{ findWatingNumberOrTime("moving", "number") }}</td>
        </tr>
        <tr>
          <th>転出</th>
          <td>{{ findWatingNumberOrTime("movingOut", "number") }}</td>
        </tr>
        <tr>
          <th>外国人異動</th>
          <td>{{ findWatingNumberOrTime("foreignerMove", "number") }}</td>
        </tr>
      </table>
      <table class="basicTable" style="margin-top: 24px" v-if="result" :class="tableColor">
        <tr>
          <th>手続き名</th>
          <th>待ち数</th>
          <th>待ち時間</th>
        </tr>
        <tr>
          <th>印鑑登録</th>
          <td>{{ findWatingNumberOrTime("sealRegistration", "number") }}</td>
          <td rowspan="2">{{ findWatingNumberOrTime("sealRegistration", "time") }}</td>
        </tr>
        <tr>
          <th>住基カード</th>
          <td>{{ findWatingNumberOrTime("basicResidentRegisterCard", "number") }}</td>
        </tr>
      </table>
      <div class="showCallingNumDlgArea">
        <div class="showCallingNumbersDialog" @click="showCallingNumbersDialog">呼出し済みの番号を表示</div>
      </div>
    </div>
    <div class="sectionInner" v-else>
      <div class="updateDateTimeArea" :class="tableColor">
        <span>更新日時</span>
        <span v-if="result">{{ result.updateDateTime }}</span>
        <img
          src="@/assets/reload_green.png"
          :class="isLoading ? 'rotation' : ''"
          @click="reloadWaitingStatus"
        />
      </div>
      <table class="basicTable" style="margin-top: 24px" v-if="result" :class="tableColor">
        <tr>
          <th>手続き名</th>
          <th>待ち数</th>
          <th>待ち時間</th>
        </tr>
        <tr>
          <th>原付バイク等の登録・抹消</th>
          <td>
            {{
            findWatingNumberOrTime("motorbikeRegistrationErasure", "number")
            }}
          </td>
          <td rowspan="2">{{ findWatingNumberOrTime("motorbikeRegistrationErasure", "time") }}</td>
        </tr>
        <tr>
          <th>軽自動車税の減免申請</th>
          <td>
            {{
            findWatingNumberOrTime(
            "lightVehicleTaxReductionApplication",
            "number"
            )
            }}
          </td>
        </tr>
      </table>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from "vuex";
import {
  LOADING_START,
  LOADING_STOP,
  SHOW_CALLING_NUMBER_DIALOG,
  HIDE_CALLING_NUMBER_DIALOG,
  SAVING_HAISAI_CALLING_NUMBERS,
  SAVING_HAISAI_CALLING_NUMBERS_ERROR,
  SAVING_HAISAI_CALLING_NUMBERS_ERROR_RESET
} from "@/store/action-types";

export default {
  name: "waitingStatus",
  components: {},
  props: ["result", "activeTab"],
  data() {
    return {
      statusResult: null,
      tableColor: "",
      baseUrl:
        "https://ww7smisxaf.execute-api.ap-northeast-1.amazonaws.com/dev/waiting-status/"
        // "https://2w7lvh2qnh.execute-api.ap-northeast-1.amazonaws.com/dev/waiting-status/"
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.isLoading,
      isCallingNumbersDialog: state => state.isCallingNumbersDialog,
      waitStatusDataToDisplay: state => state.waitStatusDataToDisplay,
      haisaiCallingNumbers: state => state.haisaiCallingNumbers
    })
  },
  watch: {
    activeTab(newValue) {
      this.switchColor(newValue);
    }
  },
  created() {
    this.switchColor(this.activeTab);
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
      showDialog: SHOW_CALLING_NUMBER_DIALOG,
      hideDialog: HIDE_CALLING_NUMBER_DIALOG,
      savingHaisaiCallingNumbers: SAVING_HAISAI_CALLING_NUMBERS,
      savingHaisaiCallingNumbersError: SAVING_HAISAI_CALLING_NUMBERS_ERROR,
      savingHaisaiCallingNumbersErrorReset: SAVING_HAISAI_CALLING_NUMBERS_ERROR_RESET
    }),
    findWatingNumberOrTime(targetType, timeOrNumber) {
      const targetArray = this.result.counterTypes.find(
        counterArray => counterArray.counterType == targetType
      );

      if (!targetArray) {
        return;
      }

      if (timeOrNumber == "time") {
        return targetArray.waitingTime;
      } else {
        return targetArray.waitingNumber;
      }
    },
    async getCallingNumberStatus(sectionType) {
      const headers = {
        "x-api-key": "tsi6gDv5qV9IfDsksRwL6aMyu9IG4swR68FVlayH"
      };
      const apiUrl = this.baseUrl + sectionType;
      await this.axios
        .get(apiUrl, {
          headers: headers,
          data: {}
        })
        .then(response => {
          const callingNumberListJson = response.data.result.counterTypes[0];
          const callingNumberListObj = callingNumberListJson.split(",");
          this.savingHaisaiCallingNumbers(callingNumberListObj);
          this.savingHaisaiCallingNumbersErrorReset();
        })
        .catch(err => {
          this.statusResult = {};
          console.error(err);
          this.savingHaisaiCallingNumbersError();
        });
    },
    reloadWaitingStatus() {
      this.$emit("reloadWaitingStatus");
    },
    async showCallingNumbersDialog() {
      await Promise.all([
        await this.loadingStart(),
        await this.getCallingNumberStatus(
          "haisaiCitizenSectionServicePastCallingNumber"
        ),
        await this.loadingStop(),
        await this.showDialog(),
      ]);
    },
    async hideCallingNumbersDialog() {
      await this.hideDialog();
    },
    switchColor(activeTab) {
      switch (activeTab) {
        case "haisaiCitizenSectionServiceOne":
          this.tableColor = "baseColor";
          break;
        case "citizenTaxSectionOne":
          this.tableColor = "citizenTaxColor";
          break;
        default:
          this.tableColor = "baseColor";
          break;
      }
    }
  }
};
</script>
<style scoped>
.showCallingNumDlgArea {
  text-align: center;
  margin: 24px 12px 0;
}
.showCallingNumDlgArea .showCallingNumbersDialog {
  display: block;
  text-align: center;
  background: #1f3c88;
  color: #ffffff;
  padding: 12px 12px;
  cursor: pointer;
}
.updateDateTimeArea {
  display: flex;
  align-items: center;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin: 12px 12px 24px;
}
.updateDateTimeArea span {
  display: block;
  padding: 12px 16px;
  font-size: 1.3rem;
}
.updateDateTimeArea span:first-child {
  font-size: 1.3rem;
  font-weight: 600;
  height: 54px;
  display: flex;
  align-items: center;
}
.updateDateTimeArea img {
  height: 28px;
  width: 28px;
  cursor: pointer;
}
.sectionInner {
  padding: 12px 12px 24px;
  max-width: 100%;
}
.basicTable {
  border-collapse: separate;
  border-spacing: 2px;
  width: 100%;
  max-width: 100%;
  padding: 0 12px;
}

.basicTable td,
.basicTable th {
  text-align: center;
  height: 60px;
  padding: 6px 16px;
  max-width: 33%;
}

.basicTable th {
  min-width: auto;
  font-size: 1.6rem;
}

.basicTable td {
  font-weight: 700;
  font-size: 1.8rem;
}

.updateDateTimeArea.baseColor span:first-child,
.basicTable.baseColor th {
  background-color: #ceddef;
}

.basicTable.baseColor td {
  color: #fff;
  background-color: #1f3c88;
}

.updateDateTimeArea.citizenTaxColor span:first-child,
.basicTable.citizenTaxColor th {
  background-color: #b0e0b2;
}

.basicTable.citizenTaxColor td {
  color: #fff;
  background-color: #4caf50;
}

@media screen and (max-width: 1000px) {
  .basicTable th {
    font-size: 1.4rem;
  }
  .basicTable td {
    font-size: 1.6rem;
  }
}
@media screen and (max-width: 768px) {
  .showCallingNumDlgArea {
    margin: 24px 0px 0;
  }
  .updateDateTimeArea span {
    display: block;
    padding: 12px 8px;
    font-size: 0.9rem;
  }
  .updateDateTimeArea span:first-child {
    font-size: 0.9rem;
    font-weight: 600;
    height: 54px;
  }
  .updateDateTimeArea img {
    height: 28px;
    width: 28px;
  }
  .updateDateTimeArea {
    justify-content: space-between;
    margin: 0 0 24px;
  }
  .sectionInner {
    padding: 24px 12px 12px;
    max-width: 100%;
  }
  .basicTable {
    border-spacing: 2px;
    padding: 0;
  }
  .basicTable td,
  .basicTable th {
    height: 30px;
    padding: 6px 12px;
    max-width: 33%;
  }
  .basicTable th {
    min-width: auto;
    font-size: 0.9rem;
  }
  .basicTable td {
    font-size: 1rem;
  }
}

.rotation {
  -webkit-animation: rotation 3s linear infinite;
  animation: rotation 3s linear infinite;
}

@-webkit-keyframes rotation {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}
</style>
