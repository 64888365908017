<template>
  <section>
    <div class="closedDisplay">
      <img src="@/assets/top_title.png" alt="" />
      <h2>ただいま{{ this.counterTitle != "" ? this.counterTitle + "は" : "" }}受付時間外です。</h2>
      <p>受付時間は平日の8時30～17:15となります。</p>
      <p>受付時間内に再度アクセスしてください。</p>
      <p>※繫忙時期の休日開庁日は除く</p>
    </div>
  </section>
</template>

<script>
import moment from 'moment';
export default {
  name: "ClosedOffice",
  props:["startTime","endTime","counterTitle"],
  data() {
    return {
      startTimeStr:moment(this.startTime,"HH:mm:ss").format("HH時mm分"),
      endTimeStr:moment(this.endTime,"HH:mm:ss").format("HH時mm分"),
    };
  },
};
</script>
<style scoped>
.sectionInner {
  padding: 12px 12px 24px;
  max-width: 100%;
}
.closedDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
}
.closedDisplay > h2 {
  font-weight: bold;
  margin-top: 100px;
}
.closedDisplay > p,
.closedDisplay > h2 {
  text-align: center;
  font-size: 1.6rem;
}
.closedDisplay > p:first-of-type {
  margin: 30px 0 0px;
}
.dispSP {
  display: none;
}
@media screen and (max-width: 768px) {
  .closedDisplay {
    padding: 0 16px;
  }
  .closedDisplay > h2 {
    margin-top: 70px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 480px) {
  .closedDisplay > h2 {
    margin-top: 50px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.2rem;
  }
  .closedDisplay > p:first-of-type {
    margin: 30px 0 0px;
  }
  .dispSP {
    display: block;
  }
}
</style>
