<template>
  <section>
    <div class="closedDisplay">
      <img src="@/assets/top_title.png" alt="" />
      <h2>ただいま、{{ counterTitle }}は<br />システムメンテナンス中です</h2>
      <p>
        ご利用の皆様にはご迷惑をおかけし、<br
          class="dispSP"
        />大変申し訳ございません。
      </p>
      <p>メンテナンス終了まで<br class="dispSP" />今しばらくお待ち下さい。</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "SystemMaintenance",
  props: ["counterTitle"],
  data() {
    return {};
  },
};
</script>
<style scoped>
.sectionInner {
  padding: 12px 12px 24px;
  max-width: 100%;
}
.closedDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100vw;
  height: calc(100vh - 120px);
}
.closedDisplay > h2 {
  font-weight: bold;
  margin-top: 100px;
  line-height: 1.4;
}
.closedDisplay > p,
.closedDisplay > h2 {
  text-align: center;
  font-size: 1.6rem;
}
.closedDisplay > p:first-of-type {
  margin: 30px 0 0px;
}
.dispSP {
  display: none;
}
@media screen and (max-width: 768px) {
  .closedDisplay {
    padding: 0 16px;
  }
  .closedDisplay > h2 {
    margin-top: 70px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 480px) {
  .closedDisplay > h2 {
    margin-top: 50px;
  }
  .closedDisplay > p,
  .closedDisplay > h2 {
    font-size: 1.2rem;
  }
  .closedDisplay > p:first-of-type {
    margin: 30px 0 0px;
  }
  .dispSP {
    display: block;
  }
}
</style>
