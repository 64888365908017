<template>
  <div class="CallingNumbersDialog" @click.self="hideCallingNumbersDialog">
    <div class="CallingNumbersDialogBody" :class="isPlusBottom">
      <div class="titleHeader">
        <h3 class="title">呼出し済みの番号</h3>
        <div class="closeBtn" @click="hideCallingNumbersDialog">
          <span>&#215;</span>
        </div>
      </div>
      <div class="updateDateTimeArea">
        <span>更新日時</span>
        <span v-if="haisaiCitizenSectionServiceOne">
          {{ haisaiCitizenSectionServiceOne.updateDateTime }}
        </span>
      </div>
      <ul class="calNumTabHead">
        <li :class="{ active: activeTab == 'allCalNum' }">
          <button @click="tabChange('allCalNum')">
            全件表示
          </button>
        </li>
        <li :class="{ active: activeTab == 'perCounter' }">
          <button @click="tabChange('perCounter')">手続きごと表示</button>
        </li>
      </ul>
      <div class="calNumTabBody">
        <div v-if="activeTab == 'allCalNum'" class="allCalNumBody">
          <div v-if="callingNumbersLocal" class="CallingNumbersBox" :class="isPlusBottom">
            <div
              class="CallingNumbersItem"
              v-for="(item, index) in callingNumbersLocal"
              :key="index"
            >{{ item }}</div>
          </div>
          <div v-else-if="isHaisaiCallingNumbersError" class="warning">
            <p>
              システムメンテナンス中です。
            </p>
            <p>
              ご利用の皆様にはご迷惑をおかけし、
              <br class="dispSP" />大変申し訳ございません。
            </p>
            <p>
              メンテナンス終了まで
              <br class="dispSP" />今しばらくお待ち下さい。
            </p>
          </div>
          <div v-else>
            <p class="aliginCenter">呼出し済番号はありません。</p>
          </div>
        </div>
        <div v-else-if="activeTab == 'perCounter'">
          <div v-if="haisaiCallingNumbers">
            <div :activeTab="activeTab">
              <section
                v-for="(perCounterItem, perCounterIndex) in perCounterHaisaiCalNums"
                :key="perCounterIndex"
              >
                <h3
                  class="counterTitle"
                  v-on:click="openAccordion(perCounterIndex)"
                  :class="{close: isAccordionShow[perCounterIndex]}"
                >{{ perCounterItem.counterName }}</h3>
                <transition
                  name="trans_slide"
                  @enter="enter"
                  @after-enter="afterEnter"
                  @leave="leave"
                  @after-leave="afterLeave"
                >
                  <div v-if="isAccordionShow[perCounterIndex]" class="menu_slide_accordion">
                    <div v-if="perCounterItem.perCounterNums.length > 0">
                      <div class="CallingNumbersBox" :class="isPlusBottom">
                        <div
                          class="CallingNumbersItem"
                          v-for="(item, index) in perCounterItem.perCounterNums"
                          :key="index"
                        >{{ item }}</div>
                      </div>
                    </div>
                    <div v-else class="isNoNumberPerCounter">
                      <p>呼出し済番号はありません。</p>
                    </div>
                  </div>
                </transition>
              </section>
            </div>
          </div>
          <div v-else-if="isHaisaiCallingNumbersError" class="warningPerCounter">
            <p>システムメンテナンス中です。</p>
            <p>
              ご利用の皆様にはご迷惑をおかけし、
              <br class="dispSP aliginCenter" />大変申し訳ございません。
            </p>
            <p>
              メンテナンス終了まで
              <br class="dispSP aliginCenter" />今しばらくお待ち下さい。
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  LOADING_START,
  LOADING_STOP,
  HIDE_CALLING_NUMBER_DIALOG
} from "@/store/action-types";
export default {
    name: "CallingNumbersDialog",
    data() {
        return {
            callingNumbersLocal: null,
            maxCnt: 29,
            activeTab: "allCalNum",
            isAccordionShow: [],
        };
    },
    watch: {
        haisaiCallingNumbers(newVal) {
            let callingNumberList = [];
            for (let item of newVal) {
                if (item != "") {
                  callingNumberList.push(item)
                }
            }
            if (callingNumberList.length === 0) {
              this.callingNumbersLocal = null;
            } else {
              this.callingNumbersLocal = callingNumberList;
            }
        },
    },
    computed: {
        ...mapState({
            isCallingNumbersDialog: state => state.isCallingNumbersDialog,
            haisaiCallingNumbers: state => state.haisaiCallingNumbers,
            isHaisaiCallingNumbersError: state => state.isHaisaiCallingNumbersError,
            haisaiCitizenSectionServiceOne: state => state.haisaiCitizenSectionServiceOne
        }),
        isPlusBottom() {
            if (this.callingNumbersLocal != [] && this.callingNumbersLocal != null) {
                return this.callingNumbersLocal.length > this.maxCnt
                    ? "plusBottom"
                    : "exceptionBottom";
            }
            else {
                return "exceptionBottom";
            }
        },
        perCounterHaisaiCalNums() {
            /* 手続きごと表示の配列を算出 */
            // 初期化
            const perCounterHaisaiCalNums = [];

            // 全件表示用配列がないなら終了
            if (this.haisaiCallingNumbers === null) {
                return perCounterHaisaiCalNums;
            }

            // 手続き関係ごとのオブジェクトを全てpush＆アコーディオン開閉フラグを作成
            for (let counterType of this.haisaiCitizenSectionServiceOne.counterTypes) {
                perCounterHaisaiCalNums.push({
                    counterName: counterType.counterName,
                    perCounterNums: [],
                });
            }

            // 全件表示用配列から、手続きごと表示用配列を作成
            this.haisaiCallingNumbers.forEach(numStr => {
                // 呼出済み番号の上2桁
                const firstTwoNum = parseInt(numStr.slice(0, 2), 10);

                // 上2桁を見てどの手続きか判定&手続きごと表示用配列にpush
                if (20 <= firstTwoNum && firstTwoNum < 29) {
                    // 転入は2000番台からスタート
                    perCounterHaisaiCalNums[0].perCounterNums.push(numStr);
                } else if (30 <= firstTwoNum && firstTwoNum < 39) {
                    // 転居は3000番台からスタート
                    perCounterHaisaiCalNums[1].perCounterNums.push(numStr);
                } else if (40 <= firstTwoNum && firstTwoNum < 49) {
                    // 転出は4000番台からスタート
                    perCounterHaisaiCalNums[2].perCounterNums.push(numStr);
                } else if (50 <= firstTwoNum && firstTwoNum < 54) {
                    // 印鑑登録は5000番台からスタート
                    perCounterHaisaiCalNums[3].perCounterNums.push(numStr);
                } else if (55 <= firstTwoNum && firstTwoNum < 59) {
                    // 住基カードは5500番台からスタート
                    perCounterHaisaiCalNums[4].perCounterNums.push(numStr);
                } else if (60 <= firstTwoNum) {
                    // 外国人移動は6000番台からスタート
                    perCounterHaisaiCalNums[5].perCounterNums.push(numStr);
                }
            });

            return perCounterHaisaiCalNums;
        },
    },
    created() {},
    mounted() {},
    methods: {
        ...mapActions({
            loadingStart: LOADING_START,
            loadingStop: LOADING_STOP,
            hideDialog: HIDE_CALLING_NUMBER_DIALOG
        }),
        async hideCallingNumbersDialog() {
            await this.hideDialog();
        },
        async tabChange(id) {
            this.activeTab = id;
        },
        openAccordion(index) {
            // 選択されたindex以外の要素をfalseで満たす
            for (let i = 0; i < this.isAccordionShow.length; i++) {
              if (i !== index) this.$set(this.isAccordionShow, i, false);
            }
            // 選択された要素の開閉切り替え
            this.$set(this.isAccordionShow, index, !this.isAccordionShow[index]);
        },
        nextFrame(fn) {
          window.requestAnimationFrame(() => window.requestAnimationFrame(fn));
        },
        enter(el) {
          el.style.overflow = 'hidden';
          el.style.height = '0';

          this.nextFrame(() => {
            el.style.height = `${el.scrollHeight}px`;
          })
        },
        leave(el) {
          el.style.overflow = 'hidden';
          el.style.height = `${el.scrollHeight}px`;

          this.nextFrame(() => {
            el.style.height = '0';
          })
        },
        afterEnter(el) {
          el.style.height = '';
          el.style.overflow = '';
        },
        afterLeave(el) {
          el.style.height = '';
          el.style.overflow = '';
        },
    },
};
</script>

<style scoped>
.warning > * {
  text-align: center;
}
.warningPerCounter {
  text-align: center;
  padding: 13px 18px 24px;
}
.isNoNumberPerCounter {
  font-size: 12px;
  vertical-align: middle;
  padding-top: 7px;
  margin-left: 15px;
}
.dispSP {
  display: none;
}
.CallingNumbersDialog {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}
.CallingNumbersDialogBody {
  width: 86vw;
  max-width: 800px;
  max-height: 82vh;
  overflow-y: scroll;
  height: auto;
  padding: 64px 16px 42px;
  background: #fff;
  position: absolute;
  /* z-index:2; */
}
.plusBottom.CallingNumbersDialogBody {
  padding: 64px 16px 64px;
}
.exceptionBottom.CallingNumbersDialogBody {
  padding: 48px 0 0;
}
.titleHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  width: 100%;
  left: 0;
  height: 48px;
  background: #1f3c88;
  color: #fff;
}
.CallingNumbersDialogBody .title {
  font-size: 1.3rem;
  text-align: center;
}
.closeBtn {
  position: absolute;
  top: 6px;
  right: 14px;
  text-align: right;
  font-size: 1.4rem;
  cursor: pointer;
}
.aliginCenter{
  text-align: center;
}
@media screen and (max-width: 480px) {
  .warning.CallingNumbersDialogBody {
    padding-top: 112px;
    padding-bottom: 16px;
  }
  .warning.CallingNumbersDialogBody .titleHeader {
    height: 96px;
    align-items: flex-end;
  }
  .warning.CallingNumbersDialogBody .title {
    line-height: 1.2;
    margin-bottom: 16px;
  }
  .dispSP {
    display: block;
  }
}

/* 更新日時表示用 */
.updateDateTimeArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid #eee;
  border-bottom: 2px solid #eee;
  margin: 12px 12px 6px;
}
.updateDateTimeArea span {
  display: block;
  font-size: 1rem;
  padding: 12px 6px;
}
.updateDateTimeArea span:first-child {
  width: 30%;
  text-align: center;
  font-weight: 600;
  background-color: #ceddef;
}
.updateDateTimeArea span:last-child {
  width: 70%;
  text-align: center;
}

/* タブ用 */
ul.calNumTabHead {
  display: flex;
  list-style: none;
  margin: 0 16px;
}
ul.calNumTabHead li {
  width: 50%;
  background: #fff;
  border: solid 1px #1f3c88;
  border-radius: 24px;
  margin: 12px 8px;
}
ul.calNumTabHead li.active {
  border: none;
  background: #ceddef;
}
ul.calNumTabHead li button {
  padding: 6px 10px;
  width: 100%;
  background: none;
  border: none;
  color: #000;
  font-size: 0.9rem;
  font-weight: 1000;
}
.calNumTabBody {
  padding: 0;
  border-top: 1px solid #1f3c88;
}
.allCalNumBody {
  padding: 13px 18px 24px;
}
/* アコーディオン用 */
section {
  position: relative;
  padding: 4px 18px;
  border-bottom: 1px solid #1f3c88;
}

.menu_slide_accordion {
  height: auto;
  min-height: 40px;
  max-height: 114px;
  overflow-y: scroll;
}

.trans_slide-enter-active, .trans_slide-leave-active {
  transition: all .5s;
}
.trans_slide-enter, .trans_slide-leave-to {
  min-height: 0;
  height: 0;
}

.counterTitle {
  position: relative;/*+マークの位置基準とするためrelative指定*/
  cursor: pointer;
  font-size:1rem;
  font-weight: 550;
  padding: 3% 3% 3% 50px;
  transition: all .5s ease;
  -webkit-tap-highlight-color: transparent;/* iOSブラウザでタップした要素が自動的に暗くなるのを防止する */
}

/* アコーディオンの＋と× */
.counterTitle::before,
.counterTitle::after{
  position: absolute;
  content:'';
  width: 15px;
  height: 2px;
  background-color: #333;
  transition: .5s
}
.counterTitle::before{
  top:48%;
  left: 15px;
  transform: rotate(0deg);
}
.counterTitle::after{    
  top:48%;
  left: 15px;
  transform: rotate(90deg);
}

/* closeというクラスがついたら形状変化 */
.counterTitle.close::before{
  transform: rotate(45deg);
}

.counterTitle.close::after{
  transform: rotate(135deg);
}

/* 呼出済み番号 */
.CallingNumbersBox {
  display: grid;
  gap: 8px 4%;
  grid-template-columns: repeat(auto-fit, minmax(20%, 22%));
  margin: 8px;
}
.CallingNumbersBox.plusBottom{
  gap: 3% 4%;
}
.CallingNumbersBox .CallingNumbersItem {
  background: #1f3c88;
  padding: 2px 8px;
  text-align: center;
  color: #fff;
  font-size: 1rem;
}
</style>