<template>
  <div class="home">
    <header :class="headerColor" v-if="!isClosed">
      {{ headerTitle }}
    </header>
    <ul class="tabHead" v-if="!isClosed">
      <li :class="{ active: activeTab == 'haisaiCitizenSectionServiceOne' }">
        <button @click="tabChange('haisaiCitizenSectionServiceOne')">
          ハイサイ市民課
        </button>
      </li>
      <li :class="{ active: activeTab == 'citizenTaxSectionOne' }">
        <button @click="tabChange('citizenTaxSectionOne')">市民税課</button>
      </li>
    </ul>
    <div
      class="tabBody"
      v-if="!isClosed && !isOverElapsed && !isEmptyObj(result)"
    >
      <div v-if="activeTab == 'haisaiCitizenSectionServiceOne'">
        <div>
          <WaitingStatus
            :result="result"
            @reloadWaitingStatus="reloadWaitingStatus"
            :activeTab="activeTab"
          />
        </div>
      </div>
      <div v-else-if="activeTab == 'citizenTaxSectionOne'">
        <div>
          <WaitingStatus
            :result="result"
            @reloadWaitingStatus="reloadWaitingStatus"
            :activeTab="activeTab"
          />
        </div>
      </div>
    </div>
    <SystemMaintenance
      v-else-if="isEmptyObj(result)"
      :counterTitle="counterTitle"
    />
    <ClosedOffice v-else-if="!isClosed && isOverElapsed" :startTime="startTime" :endTime="endTime" :counterTitle="counterTitle"/>
    <ClosedOffice v-else :startTime="startTime" :endTime="endTime" counterTitle="" />
    
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";
import {
  LOADING_START,
  LOADING_STOP,
  SHOW_CALLING_NUMBER_DIALOG,
  HIDE_CALLING_NUMBER_DIALOG,
  SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE,
  SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
  SAVING_CITIZEN_LIFE_SAFETY_SECTION,
} from "@/store/action-types";
import WaitingStatus from "@/components/WaitingStatusScreen/WaitingStatus.vue";
import SystemMaintenance from "@/components/WaitingStatusScreen/SystemMaintenance.vue";
import ClosedOffice from "@/components/WaitingStatusScreen/ClosedOffice.vue";

export default {
  name: "waitingStatusScreen",
  components: {
    WaitingStatus,
    SystemMaintenance,
    ClosedOffice,
  },
  data() {
    return {
      result: null,
      isClosed: false,
      isOverElapsed: false,
      holidays: null,
      weekEnds: [0, 6],
      startTime:"",
      endTime:"",
      //期間で開庁時間が変わるときに入力
      timeChangePeriod:{
        startDate:"2023-03-27",//YYYY-MM-DD
        endDate:"2023-04-14",//YYYY-MM-DD
        startTime:"",//HH:mm:ss
        endTime:"20:00:00",//HH:mm:ss
      },
      /* 
      *  臨時開庁日を連想配列で入れる
      *  開庁日
      *  date:"2022-06-23", 
      *  //YYYY-MM-DD
      * 
      *  開庁時間(平日と変わらない場合、""を設定)
      *  startTime:moment("00:00:00","HH:mm:ss"),
      * 
      *  閉庁時間(平日と変わらない場合、""を設定)
      *  endTime:moment("00:00:00","HH:mm:ss"),
      */
      tempOpenDays: [{
          date:"2023-03-25",
          startTime:moment("08:30:00","HH:mm:ss"),
          endTime:moment("18:00:00","HH:mm:ss"),
        },
        {
          date:"2023-04-01",
          startTime:moment("08:30:00","HH:mm:ss"),
          endTime:moment("18:00:00","HH:mm:ss"),
        },
        {
          date:"2023-04-02",
          startTime:moment("08:30:00","HH:mm:ss"),
          endTime:moment("18:00:00","HH:mm:ss"),
        }
      ], //"YYYY-MM-DD"
      tempCloseDays: ["2022-06-23"], //"YYYY-MM-DD"
      baseUrl:
      "https://ww7smisxaf.execute-api.ap-northeast-1.amazonaws.com/dev/waiting-status/",
      // "https://2w7lvh2qnh.execute-api.ap-northeast-1.amazonaws.com/dev/waiting-status/",
      activeTab: "haisaiCitizenSectionServiceOne",
      headerColor: "haisaiCitizen",
      headerTitle: "ハイサイ市民課業務（本庁舎）待ち状況",
      url: new URL(window.location.href),
      counterTitle: "ハイサイ市民課",
    };
  },
  async created() {
    switch (this.$route.query.sectionType) {
      case "haisaiCitizenSectionServiceOne":
        this.activeTab = "haisaiCitizenSectionServiceOne";
        this.headerColor = "haisaiCitizen";
        this.headerTitle = "ハイサイ市民課業務（本庁舎）待ち状況";
        this.counterTitle = "ハイサイ市民課";
        break;
      case "citizenTaxSectionOne":
        this.activeTab = "citizenTaxSectionOne";
        this.headerColor = "citizenTax";
        this.headerTitle = "市民税課業務（本庁舎）待ち状況";
        this.counterTitle = "市民税課";
        break;
      default:
        this.activeTab = "haisaiCitizenSectionServiceOne";
        this.headerColor = "haisaiCitizen";
        this.headerTitle = "ハイサイ市民課業務（本庁舎）待ち状況";
        this.counterTitle = "ハイサイ市民課";
        break;
    }
    await this.fetchOrClose();
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
      isCallingNumbersDialog: (state) => state.isCallingNumbersDialog,
      waitStatusDataToDisplay: (state) => state.waitStatusDataToDisplay,
      haisaiCitizenSectionServiceOne: (state) =>
        state.haisaiCitizenSectionServiceOne,
      nationalHealthInsuranceSection: (state) =>
        state.nationalHealthInsuranceSection,
      citizenLifeSafetySection: (state) => state.citizenLifeSafetySection,
    }),
    isResultExist() {
      return this.result ? true : false;
    },
  },
  watch: {
    isResultExist(isResultExist) {
      //resultがある場合に比較を行う 更新日時から20分経過でisClosedの表示とする
      if (isResultExist) {
        const lastUpdating = moment(
          this.result.updateDateTime,
          "YYYY/MM/DD hh:mm"
        );
        const limitMinutes = -20;
        const currentTime = moment();

        this.isOverElapsed = 
          lastUpdating.diff(currentTime, "minutes") <= limitMinutes
            ? true
            : false;
      }
    },
  },
  methods: {
    ...mapActions({
      loadingStart: LOADING_START,
      loadingStop: LOADING_STOP,
      showDialog: SHOW_CALLING_NUMBER_DIALOG,
      hideDialog: HIDE_CALLING_NUMBER_DIALOG,
      savingHaisaiCitizenSectionServiceOne:
        SAVING_HAISAI_CITIZEN_SECTION_SERVICE_ONE,
      savingNationalHealthInsuranceSection:
        SAVING_NATIONAL_HEALTH_INSURANCE_SECTION,
      savingCitizenLifeSafetySection: SAVING_CITIZEN_LIFE_SAFETY_SECTION,
    }),
    async getWaitingStatus(sectionType) {
      const headers = {
        "x-api-key": "tsi6gDv5qV9IfDsksRwL6aMyu9IG4swR68FVlayH",
      };

      const apiUrl = sectionType
        ? this.baseUrl + sectionType
        : this.baseUrl + "haisaiCitizenSectionServiceOne";
      await this.axios
        .get(apiUrl, {
          headers: headers,
          data: {},
        })
        .then((response) => {
          this.result = response.data.result;
          //this.result.updateDateTime = "2023/08/05 19:00";
          this.savingHaisaiCitizenSectionServiceOne(this.result);
        })
        .catch((err) => {
          this.result = {};
          console.error(err);
        });
    },
    async reloadWaitingStatus() {
      await Promise.all([
        await this.loadingStart(),
        await this.getWaitingStatus(this.$route.query.sectionType),
        await this.loadingStop(),
      ]);
    },
    async fetchOrClose() {
      this.isClosed = await this.isInCaseOfClosureJudgment();
      if (!this.isClosed) {
        await this.reloadWaitingStatus();
      }
    },
    async isInCaseOfClosureJudgment() {
      // 営業時間チェック
      let startTime = moment("08:30:00", "HH:mm:ss");
      let endTime = moment("17:15:00", "HH:mm:ss");
      this.startTime = startTime;
      this.endTime = endTime;

      const currentTimeString = moment().format("HH:mm:ss");
      const currentTime = moment(currentTimeString, "HH:mm:ss");
      
      // 日付チェックで必要なデータ用意
      const startMonthString = moment().startOf("month").format("YYYY-MM-DD");
      const endMonthString = moment().endOf("month").format("YYYY-MM-DD");
      const currentDate = moment();

      //平日の受付時間を変更する期間か判定
      if(this.isChangePeriod(currentDate)){
        this.startTime = this.timeChangePeriod.startTime != "" ? moment(this.timeChangePeriod.startTime,"HH:mm:ss") : this.startTime ;
        this.endTime = this.timeChangePeriod.endTime != "" ? moment(this.timeChangePeriod.endTime,"HH:mm:ss") : this.endTime ;
        startTime = this.startTime;
        endTime = this.endTime;
      }

      const holidayJp = require("@holiday-jp/holiday_jp"); // between以降に期間の開始日と終了日を指定
      this.holidays = holidayJp.between(
        new Date(startMonthString),
        new Date(endMonthString)
      );

      // 臨時閉庁日チェック
      if (this.tempCloseDays.length) {
        for (let i = 0; i < this.tempCloseDays.length; i++) {
          if (currentDate.format("YYYY-MM-DD") == this.tempCloseDays[i]) {
            return true;
          }
        }
      }

      // 臨時開庁日チェック
      if (this.tempOpenDays.length) {
        for (let i = 0; i < this.tempOpenDays.length; i++) {
          if (currentDate.format("YYYY-MM-DD") == this.tempOpenDays[i].date) {
            startTime = this.tempOpenDays[i].startTime != "" ? moment(this.tempOpenDays[i].startTime,"HH:mm:ss") : startTime ;
            endTime = this.tempOpenDays[i].endTime != "" ? moment(this.tempOpenDays[i].endTime,"HH:mm:ss") : endTime ;

            if (currentTime.isAfter(endTime) || currentTime.isBefore(startTime)) {
              return true;
            }
            return false;
          }
        }
      }

      //営業時間外かどうか
      if (currentTime.isAfter(endTime) || currentTime.isBefore(startTime)) {
        return true;
      }

      // 祝日チェック
      if (this.holidays.length) {
        for (let i = 0; i < this.holidays.length; i++) {
          const holiday = moment(this.holidays[i].date);
          if (currentDate.isSame(holiday, "day")) {
            return true;
          }
        }
      }

      // 土日チェック
      const isWeekEnd = this.weekEnds.find(
        (weekEnd) => weekEnd == currentDate.day()
      );
      if (isWeekEnd) {
        return true;
      }

      return false;
    },
    async tabChange(id) {
      await this.loadingStart();
      this.activeTab = id;
      let params = this.url.searchParams;
      params.set("sectionType", id);
      switch (id) {
        case "haisaiCitizenSectionServiceOne":
          this.headerColor = "haisaiCitizen";
          this.headerTitle = "ハイサイ市民課業務（本庁舎）待ち状況";
          break;
        case "citizenTaxSectionOne":
          this.headerColor = "citizenTax";
          this.headerTitle = "市民税課業務（本庁舎）待ち状況";
          break;
        default:
          this.headerColor = "haisaiCitizen";
          this.headerTitle = "ハイサイ市民課業務（本庁舎）待ち状況";
          break;
      }
      location.href = this.url.toString();
    },
    isEmptyObj(obj) {
      if (!obj) {
        return false;
      }
      return !Object.keys(obj).length;
    },
    isChangePeriod(cd){

      const currentDate = cd;
      const startDate = moment(this.timeChangePeriod.startDate,"YYYY-MM-DD");
      const endDate = moment(this.timeChangePeriod.endDate,"YYYY-MM-DD");

      if(currentDate >= startDate && currentDate <= endDate ){
        return true;
      }else{
        return false;
      }
      
    }
  },
};
</script>
<style scoped>
header {
  padding: 24px;
  text-align: center;
  background-color: #1f3c88;
  color: #fff;
  font-size: 2.6rem;
  max-width: 100%;
}
.haisaiCitizen {
  background-color: #1f3c88 !important;
}
.citizenTax {
  background-color: #4caf50 !important;
}
@media screen and (max-width: 1000px) {
  header {
    font-size: 2rem;
    max-width: 100%;
  }
}
@media screen and (max-width: 768px) {
  header {
    font-size: 1.6rem;
    padding: 12px;
  }
}
@media screen and (max-width: 480px) {
  header {
    font-size: 1.1rem;
    padding: 12px;
  }
}

/* tabStyles */
.tabHead {
  display: flex;
  list-style: none;
  margin: 24px 12px 0;
  border: solid 1px #eee;
}
.tabHead li {
  width: 50%;
}
.tabHead li:first-child.active {
  background: #ceddef;
}
.tabHead li:last-child.active {
  background: #b0e0b2;
}
.tabHead li button {
  padding: 12px 0;
  width: 100%;
  background: none;
  border: none;
  color: #000;
  font-size: 0.9rem;
}
.tabBody {
  padding: 0;
}
</style>
